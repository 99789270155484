import { ReactElement, useCallback } from 'react';
import { Grid } from '@mui/material';
import { Integration } from '@bas/integration-domain/models';
import { EditableMollieSettingsBlock } from '@bas/integration-domain/web/organisms';
import { MollieSettingsInputType } from '@bas/integration-domain/input-types';
import { useUpdateIntegrationSettingsMutation } from '@bas/integration-domain/mutations';

export type MollieSettingsTabProps = {
  integration: Integration;
};

const MollieSettingsTab = ({
  integration,
}: MollieSettingsTabProps): ReactElement => {
  const { mutateAsync: updateIntegrationSettingsMutation } =
    useUpdateIntegrationSettingsMutation();

  const handleUpdateSettings = useCallback(
    async (settings: MollieSettingsInputType) => {
      await updateIntegrationSettingsMutation({
        integrationId: integration.integrationId,
        provider: integration.provider,
        settings: {
          provider: integration.provider,
          ...settings,
        },
      });
    },
    [
      integration.integrationId,
      integration.provider,
      updateIntegrationSettingsMutation,
    ]
  );

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <EditableMollieSettingsBlock
          integration={integration}
          onUpdateSettings={handleUpdateSettings}
        />
      </Grid>
    </Grid>
  );
};

export default MollieSettingsTab;
