import { ProviderType } from '@bas/value-objects';

export const enabledProviders = [
  ProviderType.EXACT_GLOBE,
  ProviderType.EXACT_ONLINE,
  ProviderType.MOLLIE,
  ProviderType.SCHOUTEN_ZEKERHEID,
  ProviderType.SNEL_START,
  ProviderType.REELEEZEE,
  ProviderType.IMUIS,
  ProviderType.YUKI,
  ProviderType.WEBSITE,
  ProviderType.VERHUIS_OFFERTES,
  ProviderType.SCAN_MOVERS,
  ProviderType.WHATSAPP,
  ProviderType.OCTOPUS,
];

export const hiddenProviders: ProviderType[] = [];
