import { OfficeSkill } from '@bas/hrm-domain/models';
import {
  useEmployeeStore,
  useTenantStore,
  useUserStore,
} from '@bas/shared/state';
import { useMemo } from 'react';

export const useHasBackendRight = () => {
  const [accessToTenants, roles] = useUserStore((state) => [
    state.user?.accessToTenants,
    state.user?.roles,
  ]);
  const tenantId = useTenantStore((state) => state.tenant?.tenantId);
  const employeeSkills = useEmployeeStore(
    (state) => state.employee?.employeeSkills
  );

  return useMemo(() => {
    if (!accessToTenants) {
      return false;
    }

    if (!tenantId) {
      return false;
    }

    const tenantRights = (accessToTenants || []).find(
      (access) => access.tenantId === tenantId
    );

    if (
      employeeSkills &&
      !!employeeSkills.find((skill) =>
        [
          OfficeSkill.PLANNER,
          OfficeSkill.ADMINISTRATIVE_ASSISTANT,
          OfficeSkill.WAREHOUSE,
        ].includes(skill as OfficeSkill)
      )
    ) {
      return true;
    }

    return (
      (roles || []).includes('ROLE_SUPER_ADMIN') ||
      tenantRights?.roles.indexOf('ROLE_ADMIN') !== -1
    );
  }, [employeeSkills, roles, tenantId, accessToTenants]);
};
