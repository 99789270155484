import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { User } from '@bas/authentication-domain/models';
import { zustandStorage } from './zustandStorage';

type UserStoreType = {
  user?: User;
  setUser: (user: User) => void;
  logout: () => void;
};

export const useUserStore = create<UserStoreType>()(
  persist(
    (set) => ({
      user: undefined,
      setUser: (user: User) => set({ user }),
      logout: () => set({ user: undefined }),
    }),
    {
      name: 'user-store',
      storage: zustandStorage<UserStoreType>(),
    }
  )
);
